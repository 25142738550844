<template>
    <ion-item button class="nav-item" detail="false" @click="menuNavigation('/home')" id="tab-home">
        <ion-icon :color="buttonColor(1)" :icon="home" slot="start" />
        <ion-label :color="buttonColor(1)">{{ $t("tabs.home") }}</ion-label>
    </ion-item>
    <ion-item button class="nav-item" detail="false" @click="menuNavigation('/groups')" id="tab-groups">
        <ion-icon :color="buttonColor(14)" :icon="peopleCircle" slot="start"></ion-icon>
        <ion-label :color="buttonColor(14)">{{ $t("tabs.groups") }}</ion-label>
    </ion-item>
    <ion-item button class="nav-item" detail="false" @click="menuNavigation('/featured')" id="tab-featured">
        <ion-icon :color="buttonColor(6)" :icon="idCard" slot="start"></ion-icon>
        <ion-label :color="buttonColor(6)">{{ $t("tabs.featured") }}</ion-label>
    </ion-item>
    <ion-item button class="nav-item" detail="false" @click="menuNavigation('/explore')" id="tab-explore">
        <ion-icon :color="buttonColor(7)" :icon="globe" slot="start"></ion-icon>
        <ion-label :color="buttonColor(7)">{{ $t("tabs.explore") }}</ion-label>
    </ion-item>
    <ion-item button class="nav-item" detail="false" @click="menuNavigation('/saved')" id="tab-favorite">
        <ion-icon :color="buttonColor(5)" :icon="bookmarks" slot="start"></ion-icon>
        <ion-label :color="buttonColor(5)">{{ $t("tabs.saved") }}</ion-label>
    </ion-item>
    <ion-item button class="nav-item" detail="false" @click="menuNavigation('/messages')" id="tab-messages">
        <ion-icon :color="buttonColor(12)" :icon="chatbubble" slot="start"></ion-icon>
        <ion-label :color="buttonColor(12)">{{ $t("tabs.messages") }}</ion-label>
        <ion-badge color="danger">
            {{ this.$store.state.helpers.messageBadges }}
        </ion-badge>
    </ion-item>
    <ion-item button v-if="this.$store.state.auth.token" class="nav-item" detail="false" @click="goToNotifications()"
        id="tab-notifications">
        <ion-icon :color="buttonColor(10)" :icon="notifications" slot="start"></ion-icon>
        <ion-label :color="buttonColor(10)">{{ $t("tabs.notifications") }}</ion-label>
        <ion-badge color="danger">
            {{ this.$store.state.helpers.badges }}
        </ion-badge>
    </ion-item>
    <ion-item v-if="this.$store.state.auth.token" button class="nav-item" detail="false" @click="goToProfile()"
        id="tab-profile">
        <ion-icon :color="buttonColor(3)" :icon="personCircle" slot="start"></ion-icon>
        <ion-label :color="buttonColor(3)">{{ $t("tabs.profile") }}</ion-label>
    </ion-item>
    <ion-item button class="nav-item" detail="false" @click="menuNavigation('/friends/2/0')" id="tab-following">
        <ion-icon :color="buttonColor(4)" :icon="people" slot="start"></ion-icon>
        <ion-label :color="buttonColor(4)">{{ $t("tabs.following") }}</ion-label>
    </ion-item>

</template>
<script>
import {
    IonItem,
    IonLabel,
    IonIcon,
    IonBadge,
    modalController,
    alertController,
    isPlatform
} from "@ionic/vue";
import {
    personCircle,
    people,
    bookmarks,
    peopleCircle,
    idCard,
    home,
    notifications,
    chatbubble,
    compass
} from "ionicons/icons";

import getEnv from "@/utils/env";
import { PushNotifications } from '@capacitor/push-notifications';
import SignupModal from '@/components/SignupModal.vue'

export default {
    name: "ChoiceMenu",
    components: {
        IonLabel,
        IonIcon,
        IonItem,
        IonBadge
    },
    setup() {
        return {
            home,
            personCircle,
            people,
            bookmarks,
            peopleCircle,
            idCard,
            getEnv,
            notifications,
            chatbubble,
            compass
        };
    },
    methods: {
        menuNavigation(url) {
            if (!this.$store.state.auth.token) {
                //console.log("show login popup");
                this.presentLoginAlertConfirm();
                //this.$router.push('/login');
            } else {
                // check if you tapped the home button again
                var keyFlag = "";
                if (this.$route.path == url) {
                    if (this.$route.path == "/saved") {
                        keyFlag = "saved";
                    } else if (this.$route.path == "/home") {
                        keyFlag = "home";
                    } else if (this.$route.path == "/explore") {
                        keyFlag = "explore";
                    } else if (this.$route.path == "/featured") {
                        keyFlag = "featured";
                    } else if (this.$route.path == "/elections") {
                        keyFlag = "elections";
                    } else if (this.$route.path == "/messages") {
                        keyFlag = "messages";
                    } else if (this.$route.path == "/groups") {
                        keyFlag = "groups";
                    } /* else if (this.$route.path == "/myprofile") {
              keyFlag = "myprofile";
            }*/
                }
                //console.log(keyFlag, 'keyFlag');
                //console.log(url, 'url');
                //console.log(this.$route.path + ' ' + url);
                if (keyFlag != "") {
                    //console.log('going to the same place');
                    //var streamType = "";
                    /*if (keyFlag == "myprofile") {
                      streamType = "myprofile";
                      this.$store.commit("pikstream/setLastId", 0);
                      this.$store.commit("pikstream/setTotalList", 7);
                      this.$store.commit("pikstream/setMyProfileRequest", null);
                    } else*/
                    if (keyFlag == "saved") {
                        this.$store.commit("pikstream/setFavoriteRequest", null);
                        this.$store.dispatch("pikstream/getStream", {
                            streamName: 'favorites',
                            filter: '',
                            imageid: '',
                            storyid: '',
                            total_list: this.$store.state.pikstream.totalList.favorite,
                            last_id: this.$store.state.pikstream.lastId.favorite,
                            userid: ''
                        });
                    } else if (keyFlag == "home") {
                        this.$store.commit("pikstream/setFollowRequest", null);
                        this.$store.dispatch("pikstream/getStream", {
                            streamName: 'home',
                            filter: '',
                            imageid: '',
                            storyid: '',
                            total_list: this.$store.state.pikstream.totalList.follow,
                            last_id: this.$store.state.pikstream.lastId.follow,
                            userid: ''
                        });

                    } else if (keyFlag == "explore") {
                        this.$store.commit("explore/setRequest", []);
                        this.$store.dispatch("explore/getData", {
                            page: 0,
                            type: null,
                        });
                    } else if (keyFlag == "featured") {
                        this.$store.commit("featured/setRequest", []);
                        this.$store.dispatch("featured/getData", {
                            page: 0,
                            type: null,
                            current_page: 0,
                            total_list: 30,
                        });
                    } else if (keyFlag == "elections") {
                        this.$store.commit("elections/setRequest", []);
                        this.$store.dispatch("elections/getData", {
                            page: 0,
                            type: '',
                            current_page: 0,
                            total_list: 30,
                        });
                    } else if (keyFlag == "messages") {
                        this.$store.commit("messages/setThreads", []);
                        this.$store.dispatch("messages/allThreads");
                    } else if (keyFlag == "groups") {
                        this.$store.commit("groups/setUserGroups", null);
                        this.$store.commit("groups/setFeaturedGroups", null);
                        this.$store.commit("groups/setSectionGroups", null);
                    }

                }
                let headerValue = this.getHeaderName(url);
                this.$store.dispatch("header/general", {
                    page: headerValue
                });

                if (this.$store.state.auth.token != '') {
                    this.$store.dispatch("helpers/getNotifyBadges");
                }

                this.$store.commit("helpers/setToggleMenu", false);
                this.$router.replace({ path: url });

            }
        },
        getHeaderName(url) {
            let title = "";
            if (url == "/saved") {
                title = this.$t("header.saved");
            } else if (url == "/home") {
                title = this.$t("header.home");
            } else if (url == "/explore") {
                title = this.$t("header.explore");
            } else if (url == "/featured") {
                title = this.$t("header.featured");
            } else if (url == "/friends/2/0" || url == '/followers') {
                title = this.$t("header.followers");
            } else if (url == "/friends/1/0" || url == '/following') {
                title = this.$t("header.following");
            } else if (url == "/options") {
                title = this.$t("header.options");
            } else if (url == "/notifications") {
                title = this.$t("header.notifications");
            } else if (url == "/user-login") {
                title = this.$t("header.login");
            } else if (url == "/signup") {
                title = this.$t("header.signup");
            } else if (url == "/messages") {
                title = this.$t("header.messages");
            } else if (url == "/elections") {
                title = this.$t("header.elections");
            }
            return title;
        },
        buttonColor(index) {
            if (index == 1 && this.$route.path == "/home") {
                return "primary";
            } else if (index == 3 && this.$route.path == "/profile/" + this.myProfileId) {
                return "primary";
            } else if (index == 4 && this.$route.path == "/friends/2/0") {
                return "primary";
            } else if (index == 5 && this.$route.path == "/saved") {
                return "primary";
            } else if (
                index == 6 &&
                (this.$route.path == "/featured" ||
                    this.$route.path == "/premium")
            ) {
                return "primary";
            } else if (index == 7 && this.$route.path == "/explore") {
                return "primary";
            } else if (index == 8 && this.$route.path == "/groups") {
                return "primary";
            } else if (index == 9 && this.$route.path == "/options") {
                return "primary";
            } else if (index == 10 && this.$route.path == "/notifications") {
                return "primary";
            } else if (index == 11 && this.$route.path == "/menu") {
                return "primary";
            } else if (index == 12 && (this.$route.name == "messages" || this.$route.name == "message-thread")) {
                return "primary";
            } else if (index == 13 && (this.$route.name == "elections")) {
                return "primary";
            } else if (index == 14 && (this.$route.name == "groups")) {
                return "primary";
            }

        },
        async presentLoginAlertConfirm() {
            const alert = await alertController.create({
                cssClass: "my-custom-class",
                header: this.$t('alerthead.login'),
                message: this.$t('alertmessage.login', { app: getEnv("APP_NAME") }),
                buttons: [
                    {
                        text: this.$t('header.signup'),
                        cssClass: 'primary',
                        handler: () => {
                            this.signup();
                        },
                    },
                    {
                        text: this.$t('header.login'),
                        handler: () => {
                            this.login();
                        },
                    },
                    {
                        text: this.$t('buttons.cancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                    },

                ],
            });
            return alert.present();
        },
        signup() {
            this.presentSignup('signup');
        },
        login() {
            this.presentSignup('login');
        },
        async presentSignup(type, button) {
            let title = this.$t('header.login');
            if (type == "signup") {
                title = this.$t('header.signup');
            }
            const modal = await modalController
                .create({
                    component: SignupModal,
                    cssClass: 'modal-custom-class',
                    componentProps: {
                        title: title,
                        loginType: type,
                        source: 'link'
                    }
                })
            await modal.present();

            const { role } = await modal.onDidDismiss();
            if (role == 'loggedin') {
                if (button == 'buy') {
                    this.openBuyModal();
                }
            }
        },
        async registerPushes() {
            this.registerNotifications();
            this.addListeners();
            /* const getDeliveredNotifications = async () => {
              const notificationList = await PushNotifications.getDeliveredNotifications();
              console.log('delivered notifications', notificationList);
            }
            getDeliveredNotifications(); */
        },
        async registerNotifications() {
            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
                throw new Error('User denied permissions!');
            }

            await PushNotifications.register();
        },
        async addListeners() {
            await PushNotifications.addListener('registration', async (token) => {
                console.info('Registration token: ', token.value);
                let android = '';
                if (isPlatform('android')) {
                    android = 'YES';
                }
                let response = await this.$store.dispatch("helpers/notifyToken", {
                    token_id: token.value,
                    identifier: '',
                    aws: true,
                    android: android
                });
                if (response) {
                    if (response['error'] != null) {
                        console.log('Error: ' + response['error']);
                    } /*else {
              console.log(response, 'got it');
            }*/
                } else {
                    console.log('Unkown error');
                }
            });

            await PushNotifications.addListener('registrationError', err => {
                console.error('Registration error: ', err.error);
            });

            await PushNotifications.addListener('pushNotificationReceived', notification => {
                console.log('Push notification received: ', notification);
            });

            await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
                console.log('Push notification action performed', notification.actionId, notification.inputValue);
            });
        },

        goToProfile() {
            if (isPlatform('hybrid')) { // only register pushes on phone devices
                this.registerPushes();
            }
            this.$router.replace({ name: 'profile', params: { id: this.myProfileId } });
        },
        goToNotifications() {
            if (isPlatform('hybrid')) { // only register pushes on phone devices
                this.registerPushes();
            }

            if (this.$route.path == "/notifications") {
                this.$store.commit("profile/setNotifications", null);
                this.$store.commit("profile/setRequests", null);
                this.$store.dispatch("profile/notifications", {
                    totalList: 50,
                    currentPage: 0,
                    update: false,
                    type: 'notifications'
                });
            }
            this.$store.dispatch("profile/requests");
            this.$router.replace('/notifications');
        }
    },
    computed: {
        myProfileId() {
            return this.$store.state.profile.id;
        },
        toggleMenu() {
            return this.$store.state.helpers.toggleMenu;
        }
    }
};
</script>
  
