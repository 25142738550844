<template>
  <ion-split-pane ref="split-pane" :when="showMenu()" content-id="main">
    <!--  the side menu  -->
    <ion-menu content-id="main">
      <ion-header :translucent="true">
        <ion-toolbar class="side-bar-menu" color="light">
          <div slot="start" style="cursor:pointer; padding-left:32px;" @click="menuNavigation('/home')">
            <img v-if="this.darkMode=='true'" srcset="/assets/icon/logo_dark.png 1x, /assets/icon/logo_dark@2x.png 2x, /assets/icon/logo_dark@3x.png 3x" />
            <img v-else srcset="/assets/icon/logo_light.png 1x, /assets/icon/logo_light@2x.png 2x, /assets/icon/logo_light@3x.png 3x"/>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list class="nav-item side-bar-menu" lines="none">
          <div v-if="getEnv('APP_NAME') == 'Vocl'">
            <VoclMenu />
          </div>
          <div v-if="getEnv('APP_NAME') == 'ChoiceSocial'">
            <ChoiceMenu />
          </div>
          <div v-if="getEnv('APP_NAME') == 'As-one'">
            <AsoneMenu />
          </div>
          <ion-item-divider>
            <ion-label> {{ $t("tabs.manage") }} </ion-label>
          </ion-item-divider>
          <ion-item button class="nav-item" detail="false" @click="menuNavigation('/options')" id="tab-options">
            <ion-icon :color="buttonColor(9)" :icon="cog" slot="start"></ion-icon>
            <ion-label :color="buttonColor(9)">{{ $t("tabs.options") }}</ion-label>
          </ion-item>
          <ion-item class="nav-item" detail="false" button :href="getEnv('HELP_LINK')" target="_blank">
            <ion-icon :icon="helpCircle" slot="start"></ion-icon>
            <ion-label>{{ $t("tabs.howto") }}</ion-label>
          </ion-item>
          <ion-item class="nav-item" detail="false" button @click="openFeedbackModal()">
            <ion-icon :icon="send" slot="start"></ion-icon>
            <ion-label>{{ $t("tabs.feedback") }}</ion-label>
          </ion-item>
          <ion-item class="nav-item" detail="false" lines="none">
            <ion-icon slot="start" :icon="moon"></ion-icon>
            <ion-toggle @ionChange="darkModeToggle" :checked="darkMode" id="themeToggle">{{ $t("tabs.toggle") }}</ion-toggle>
          </ion-item>
          <ion-item-divider v-if="getEnv('STORE_LINK') != '' || getEnv('LINK_1') != ''">
            <ion-label> {{ $t("tabs.links") }} </ion-label>
          </ion-item-divider>
          <ion-item v-if="getEnv('LINK_1') != '' && getEnv('LINK_1') != undefined" button class="nav-item" detail="false"
            :href="getEnv('LINK_1')" target="_blank">
            <ion-icon v-if="getEnv('LINK_IMAGE_1')!=''" :name="getEnv('LINK_IMAGE_1')" slot="start" />
            <ion-icon v-else :icon="link" slot="start" />
            <ion-label>{{ getEnv('LINK_LABEL_1') }}</ion-label>
          </ion-item>
          <ion-item v-if="getEnv('LINK_2') != '' && getEnv('LINK_2') != undefined" button class="nav-item" detail="false"
            :href="getEnv('LINK_2')" target="_blank">
            <img :src="getEnv('LINK_IMAGE_2')" slot="start">
            <ion-label><p>{{ getEnv('LINK_LABEL_2') }}</p></ion-label>
          </ion-item>
          <ion-item v-if="getEnv('LINK_3') != '' && getEnv('LINK_3') != undefined" button class="nav-item" detail="false"
            :href="getEnv('LINK_3')" target="_blank">
            <img :src="getEnv('LINK_IMAGE_3')" slot="start">
            <ion-label><p>{{ getEnv('LINK_LABEL_3') }}</p></ion-label>
          </ion-item>
          <ion-item-divider>{{ $t("tabs.logout") }}</ion-item-divider>
          <ion-item v-if="this.$store.state.auth.token != ''" class="nav-item" detail="false" button @click="logout">
            <ion-icon :icon="logOut" slot="start"></ion-icon>
            <ion-label>{{ $t("tabs.logout") }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- the main content -->
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</template>

<script>
import {
  IonList,
  IonRouterOutlet,
  IonContent,
  IonItem,
  IonMenu,
  IonSplitPane,
  IonToolbar,
  IonHeader,
  IonLabel,
  IonIcon,
  IonItemDivider,
  IonToggle,
  modalController,
  alertController,
  isPlatform
} from "@ionic/vue";
import {
  logOut,
  cog,
  helpCircle,
  send,
  moon,
  link
} from "ionicons/icons";

import FeedbackModal from "@/components/SendFeedback.vue";
//import HowToModal from '@/components/HowToContainer.vue'
import { defineComponent } from 'vue'
import getEnv from "@/utils/env";
import { PushNotifications } from '@capacitor/push-notifications';
import SignupModal from '@/components/SignupModal.vue'
import { Browser } from '@capacitor/browser';
import VoclMenu from "@/components/nav/VoclMenu.vue";
import ChoiceMenu from "@/components/nav/ChoiceMenu.vue";
import AsoneMenu from "@/components/nav/AsoneMenu.vue";

export default defineComponent({
  name: "SplitPane",
  components: {
    IonMenu,
    IonSplitPane,
    IonToolbar,
    IonHeader,
    IonLabel,
    IonIcon,
    IonList,
    IonItem,
    IonItemDivider,
    IonToggle,
    IonContent,
    IonRouterOutlet,
    VoclMenu,
    ChoiceMenu,
    AsoneMenu
  },
  setup() {
    return {
      logOut,
      cog,
      helpCircle,
      send,
      moon,
      link,
      getEnv
    };
  },
  data() {
    return {
      profilepath: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET') + '/profile/',
      pollData: null
    };
  },
  head() {
    return {
      title: this.$store.state.header.title,
      meta: this.$store.state.header.meta,
      script: [
        { src: 'https://' + getEnv('FATHOM_URL') + '/script.js', 'data-spa': 'auto', 'data-site': getEnv('FATHOM_ID') },
      ],
    }
  },
  mounted() {
    if (this.darkMode == "true") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    if (this.$store.state.auth.token) {
      clearInterval(this.pollData); //clear out the old one
      this.beginPolling();
    }
  },
  methods: {
    menuNavigation(url) {
      if (!this.$store.state.auth.token) {
        //console.log("show login popup");
        this.presentLoginAlertConfirm();
        //this.$router.push('/login');
      } else {
        // check if you tapped the home button again
        var keyFlag = "";
        if (this.$route.path == url) {
          if (this.$route.path == "/saved") {
            keyFlag = "saved";
          } else if (this.$route.path == "/home") {
            keyFlag = "home";
          } else if (this.$route.path == "/explore") {
            keyFlag = "explore";
          } else if (this.$route.path == "/featured") {
            keyFlag = "featured";
          } else if (this.$route.path == "/elections") {
            keyFlag = "elections";
          } else if (this.$route.path == "/messages") {
            keyFlag = "messages";
          } else if (this.$route.path == "/groups") {
            keyFlag = "groups";
          }
        }
        if (keyFlag == "home") {
          this.$store.commit("pikstream/setFollowRequest", null);
          this.$store.dispatch("pikstream/getStream", {
            streamName: 'home',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.follow,
            last_id: this.$store.state.pikstream.lastId.follow,
            userid: ''
          });
        }
        let headerValue = this.getHeaderName(url);
        this.$store.dispatch("header/general", {
          page: headerValue
        });

        if (this.$store.state.auth.token != '') {
          this.$store.dispatch("helpers/getNotifyBadges");
        }

        this.$store.commit("helpers/setToggleMenu", false);
        this.$router.replace({ path: url });

      }
    },
    getHeaderName(url) {
      let title = "";
      if (url == "/saved") {
        title = this.$t("header.saved");
      } else if (url == "/home") {
        title = this.$t("header.home");
      } else if (url == "/explore") {
        title = this.$t("header.explore");
      } else if (url == "/featured") {
        title = this.$t("header.featured");
      } else if (url == "/friends/2/0" || url == '/followers') {
        title = this.$t("header.followers");
      } else if (url == "/friends/1/0" || url == '/following') {
        title = this.$t("header.following");
      } else if (url == "/options") {
        title = this.$t("header.options");
      } else if (url == "/notifications") {
        title = this.$t("header.notifications");
      } else if (url == "/user-login") {
        title = this.$t("header.login");
      } else if (url == "/signup") {
        title = this.$t("header.signup");
      } else if (url == "/messages") {
        title = this.$t("header.messages");
      } else if (url == "/elections") {
        title = this.$t("header.elections");
      }
      return title;
    },
    buttonColor(index) {
      if (index == 9 && this.$route.path == "/options") {
        return "primary";
      }
    },
    async logout() {
      //console.log('submitted ' + this.username + ":" + this.password);
      let response = await this.$store.dispatch("auth/logout");
      if (response) {
        this.$store.commit("pikstream/logout");
        this.$store.commit("groups/logout");
        this.$store.commit("profile/logout");
        this.$store.commit('comments/logout');
        this.$store.commit('profile/resetProfile');
        
        this.$store.dispatch("header/general", {
          page: this.$t('header.login')
        });
        this.$router.replace('/login');
        //window.location = "/login";
      } else {
        // you can add condition here
      }
    },
    async openFeedbackModal() {
      const modal = await modalController.create({
        component: FeedbackModal,
        cssClass: "editpost-custom-class",
        componentProps: {
          title: this.$t('options.feedback'),
        },
      });
      return await modal.present();
    },
    async presentLoginAlertConfirm() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: this.$t('alerthead.login'),
        message: this.$t('alertmessage.login', {app: getEnv("APP_NAME")}),
        buttons: [
          {
            text: this.$t('header.signup'),
            cssClass: 'primary',
            handler: () => {
              this.signup();
            },
          },
          {
            text: this.$t('header.login'),
            handler: () => {
              this.login();
            },
          },
          {
            text: this.$t('buttons.cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },

        ],
      });
      return alert.present();
    },
    signup() {
      if(getEnv('APP_NAME') == 'VoclNEW'){
        this.fsbnConnect('signup');
      }else{
        this.presentSignup('signup');
      }
    },
    login() {
      if(getEnv('APP_NAME') == 'VoclNEW'){
        this.fsbnConnect('login');
      }else{
        this.presentSignup('login');
      }
      
    },
    async openLink(link_url) {
      if (link_url != '') {
        if (isPlatform('hybrid') || isPlatform('electron')) {
          await Browser.open({ url: link_url, presentationStyle: 'popover' });
        }else{
          window.open(link_url, '_blank');
        }
      }
    },
    async fsbnConnect(type) {
      let redirect = 'https://' + window.location.hostname + '/idauth';
      if (isPlatform('hybrid') || isPlatform('electron')) {
        redirect = 'https://' + getEnv('SHARE_HOSTNAME') + '/idauth';
      }
      const client_id = 'ceb0f61a-976b-4b29-a59f-55ec3f600d6f';
      let url_add = '';
      if(type=="signup") url_add = '&goto=signup';
      if(getEnv('REQUEST_URL')=='https://api.vocl.com/'){
        this.openLink('https://signin.frankspeech.com/OAuth/Start?redirect_uri='+redirect+'&client_id='+client_id+'&response_type=code&state='+type+url_add);
      }else{
        this.openLink('https://test-signin.frankspeech.com/OAuth/Start?redirect_uri='+redirect+'&client_id='+client_id+'&response_type=code&state='+type+url_add);
      }
    },
    async presentSignup(type, button) {
      let title = this.$t('header.login');
      if (type == "signup") {
        title = this.$t('header.signup');
      }
      const modal = await modalController
        .create({
          component: SignupModal,
          cssClass: 'modal-custom-class',
          componentProps: {
            title: title,
            loginType: type,
            source: 'link'
          }
        })
      await modal.present();

      const { role } = await modal.onDidDismiss();
      if (role == 'loggedin') {
        if (button == 'buy') {
          this.openBuyModal();
        }
      }
    },
    darkModeToggle() {
      if (this.darkMode == "false") {
        document.body.classList.add("dark");
        this.$store.dispatch("helpers/saveDarkMode", {
          darkModeSwitch: "true",
        });
      } else {
        document.body.classList.remove("dark");
        this.$store.dispatch("helpers/saveDarkMode", {
          darkModeSwitch: "false",
        });
      }
    },
    async beginPolling() {
      //console.log('begin poll for messages');
      let pollTimeOut = 20000;
      setTimeout(() => {
        // note: this is a hack to fix an issue in ionic auto-grow
        this.count = this.count + 1;
      }, 100);

      //NOTE: turn off and test separately
      this.pollData = setInterval(() => {
        this.pollNotifications();
      }, pollTimeOut);
    },
    async pollNotifications() {
      //console.log('notifications poll: ' + new Date());
      if (this.$store.state.auth.token != '') {
        this.$store.dispatch("helpers/getNotifyBadges");
        if(isPlatform('hybrid') && getEnv('APP_NAME')!='Vocl'){ 
          await PushNotifications.removeAllDeliveredNotifications();
        }
      }
    },
    showMenu() {
      let canShowMenu = 'lg';
      if (this.toggleMenu == true) {
        canShowMenu = true;
      }
      if (this.$store.state.auth.token == '') {
        canShowMenu = false;
      }
      if (this.$route.name == "embed"){
        canShowMenu = false;
      }
      return canShowMenu;
    }
  },
  computed: {
    myProfilePic() {
      return this.$store.state.profile.pic;
    },
    myProfileId() {
      return this.$store.state.profile.id;
    },
    toggleMenu() {
      return this.$store.state.helpers.toggleMenu;
    },
    darkMode() {
      return this.$store.state.helpers.darkMode;
    }
  }
});
</script>
<style scoped>
ion-split-pane {
  --side-width: 270px;
  --side-max-width: 270px;
  /* --border: 1px solid lightgray; */
}


ion-thumbnail {
  --size: 40px;
  margin-left: 20px;
}

.title-text {
  font-size: 0.7em;
}

@media only screen and (min-width: 599px) {
  .title-text {
    font-size: 1.0em;
  }
}

@media only screen and (min-width: 1600px) {
  ion-split-pane {
    --side-width: 460px;
    --side-max-width: 460px;
  }

  .side-bar-menu {
    margin-left: 200px;
  }
}

@media only screen and (min-width: 2600px) {
  ion-split-pane {
    --side-width: 900px;
    --side-max-width: 900px;
  }

  .side-bar-menu {
    margin-left: 600px;
  }
}

@media only screen and (min-width: 3600px) {
  ion-split-pane {
    --side-width: 1200px;
    --side-max-width: 1200px;
  }

  .side-bar-menu {
    margin-left: 900px;
  }
}
</style>
