window.addEventListener('unhandledrejection', event => {
  console.error('Unhandled rejection:', event.reason);
});

import { createApp } from 'vue'
import { createHead, VueHeadMixin } from '@vueuse/head'
import { createGtm } from '@gtm-support/vue-gtm';
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import { register } from 'swiper/element/bundle';
import { addIcons } from 'ionicons';

import { IonicVue, isPlatform } from '@ionic/vue';
import * as Sentry from "@sentry/vue";
import { createGmapVuePlugin } from '@gmap-vue/v3';
import OneSignal from 'onesignal-cordova-plugin';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import 'intl-tel-input/build/css/intlTelInput.css';

/* Theme variables */
import './theme/variables.css';
import './theme/dash.css';
import '@gmap-vue/v3/dist/style.css';

/* Custom Icons */
import voclIcon from './assets/vocl-social-icon.svg';
import coaIcon from './assets/coa-icon.svg';
import lindelltvIcon from './assets/lindelltv-icon.svg';
import lindelltvColorIcon from './assets/lindelltv-icon-color.svg';

// import '../node_modules/video.js/dist/video-js.min.css';
import './registerServiceWorker'

//import messages from '@intlify/unplugin-vue-i18n/messages'
import en from './locales/en'
import es from './locales/es'
import pt from './locales/pt'

let setLocale = 'en';
let profileLocale = store.state.profile.locale;
if(profileLocale=='en' || profileLocale=='es' || profileLocale=='pt'){
  setLocale = profileLocale;
}

const i18n = createI18n({
  locale: setLocale,
  legacy: false,
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en,
    pt,
    es
  }
})


addIcons({
  'vocl-icon': voclIcon,
  'coa-icon': coaIcon,
  'lindelltv-icon': lindelltvIcon,
  'lindelltv-color-icon': lindelltvColorIcon
});

// Call this function when your app start
async function OneSignalInit() {
  OneSignal.initialize("4bc05e94-ed8e-4578-88df-43367e34d0c6");
  //OneSignal.Notifications.requestPermission(); // we let onesignal request permissions
  // Uncomment to set OneSignal device logging to VERBOSE  
  //window.plugins.OneSignal.Debug.setLogLevel(6);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  //window.plugins.OneSignal.initialize("4bc05e94-ed8e-4578-88df-43367e34d0c6");
  /* window.plugins.OneSignal.Notifications.setNotificationOpenedHandler(function(opened) {
    let notificationData = JSON.stringify(opened);
    console.log('notificationOpenedCallback: ' + notificationData);
  }); 
  const listener = (PushSubscriptionChangedState) => {
    console.log("Push subscription changed: " + (PushSubscriptionChangedState));
  };

  // Add the listener
  window.plugins.OneSignal.User.pushSubscription.addEventListener("change", listener); */

  /* window.plugins.OneSignal.Notifications.addEventListener("click", (jsonData) => {
    let notificationData = JSON.stringify(jsonData)
    console.log('notificationOpenedCallback: ' + notificationData);
  });*/ 

  // iOS - Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  /*   window["plugins"].OneSignal.Notifications.requestPermission(function(accepted) {
      console.log("User accepted notifications: " + accepted);
    }); */
}

register(); 

let traceTargets = ["localhost"];
let sentryEnvironment = "development";
if(import.meta.env['VITE_REQUEST_URL'=='dev.pikmobile.com']){
  traceTargets = ["localhost", /^https:\/\/dev\.pikmobile\.com/];
}else if(import.meta.env['VITE_REQUEST_URL'=='api.choicesocial.us']){
  traceTargets = ["localhost", /^https:\/\/api\.choicesocial\.us/];
  sentryEnvironment = "production";
}else if(import.meta.env['VITE_REQUEST_URL'=='dev-api.vocl.com']){
  traceTargets = ["localhost", /^https:\/\/dev-api\.vocl\.com/];
}else if(import.meta.env['VITE_REQUEST_URL'=='api.vocl.com']){
  traceTargets = ["localhost", /^https:\/\/api\.vocl\.com/];
  sentryEnvironment = "production";
}else if(import.meta.env['VITE_REQUEST_URL'=='dev-api.asone.social']){
  traceTargets = ["localhost", /^https:\/\/dev-api\.asone\.social/];
}else if(import.meta.env['VITE_REQUEST_URL'=='api.asone.social']){
  traceTargets = ["localhost", /^https:\/\/api\.asone\.social/];
  sentryEnvironment = "production";
}

const head = createHead()
const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router)
  .use(i18n)
  .mixin(VueHeadMixin)
  .use(head)
  
  Sentry.init({
    app,
    dsn: import.meta.env['VITE_SENTRY_DSN'],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: sentryEnvironment,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: traceTargets,
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      // Modify or drop the event here
      //console.log(event.request.headers['User-Agent'], 'request');
      let agent = event.request.headers['User-Agent'];
      if (agent!=null && agent.includes('Prerender (+https://github.com/prerender/prerender)')) {
        // don't send any prerender data
        return null;
      }
      return event;
    },
  });

if (import.meta.env['VITE_APP_NAME'] == 'Vocl') { // Vocl uses OneSignal and Google Analytics
  //document.addEventListener("deviceready", OneSignalInit, false);
  const gtm = createGtm({
    id: 'GTM-M357MQJS', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    /* queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x',
    },
    source: 'https://customurl.com/gtm.js', // Add your own serverside GTM script
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag*/
    //enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    //debug: true, // Whether or not display console logs debugs (optional)
    //loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    //trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });
  app.use(gtm);
  app.use(createGmapVuePlugin({
    load: { key: 'AIzaSyBKvamvBCjTyBhfkhbthecKX7fP5ROhwOQ' },
  }))
}

/* router.isReady().then(() => {
  app.mount('#app');  
}); */

router.isReady()
  .then(() => {
    app.mount('#app');
    if (import.meta.env['VITE_APP_NAME'] == 'Vocl' && isPlatform('hybrid')) {
      OneSignalInit();
    }
  })
  .catch(error => {
    console.error('Error during router initialization:', error);
  });

